import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import withStyles from "@material-ui/core/styles/withStyles"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
// import SwipeDialog from '../components/dialogs/SwipeDialog';
import Card from "../components/card/Card"
import CardHeader from "../components/card/CardHeader"
import CardBody from "../components/card/CardBody"
import GridContainer from "../components/Grid/GridContainer"
import GridItem from "../components/Grid/GridItem"
import Avatar from "../components/Avatar"
import MenuContext from "../context/menu-context"

// const backgroundShape = require('../images/shape.svg');

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["200"],
    overflow: "hidden",
    backgroundSize: "cover",
    // backgroundPosition: '0 400px',
    // marginTop: 20,
    padding: 20,
    paddingBottom: 300,
  },
  grid: {
    width: "90%",
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)",
      marginTop: 40,
    },
  },
  paper: {
    // padding: theme.spacing(3),
    textAlign: "left",
    marginLeft: 15,
    minHeight: "350px",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: theme.spacing(4),
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "900",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  textStyle: {
    fontSize: "20px",
    fontWeight: "300",
  },
})

const Main = props => {
  const { classes, data } = props

  const { setMenu } = useContext(MenuContext)
  const currentPath = props.location.pathname

  // Run once after initial render
  useEffect(() => {
    setMenu(currentPath)
  }, [currentPath, setMenu])
  // const [learnMoredialog, setLearnMoredialog] = useState(false);

  // const openDialog = (event) => {
  //   setLearnMoredialog(true);
  // };

  // const dialogClose = (event) => {
  //   setLearnMoredialog(false);
  // };
  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <Grid container justify="center">
          <Grid
            alignItems="center"
            justify="center"
            container
            className={classes.grid}
          >
            <Grid item xs={12} md={4}>
              <Avatar ImgData={data.myImage} />
            </Grid>

            <Grid item xs={12} md={8}>
              <Card className={classes.paper}>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>ABOUT ME</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12}>
                      <div className={classes.textStyle}>
                        {/* <p>
                          I am a fifth year PhD candidate working with{" "}
                          <a href="http://www.depstein.net/"> Daniel Epstein</a>{" "}
                          and
                          <a href="https://www.gillianhayes.com/">
                            {" "}
                            Gillian Hayes
                          </a>{" "}
                          in the Informatics department at the University of
                          California, Irvine.
                        </p> */}
                        <p>
                          I am an Assistant Professor in Computer Science at the
                          University of Iowa.
                        </p>
                        <p>
                          I study how to leverage people’s discovery and
                          understanding of personal data through the use of
                          multiple devices to help manage their health and
                          wellbeing, individually or collaboratively in family
                          settings. Some of my working is in supporting
                          neurodivergent people and technolgy-mediated
                          co-regulation with family members.
                        </p>
                        <p>
                          I acquired a PhD in the Informatics department at the
                          University of California, Irvine. Previously, I
                          completed a M.Sc. and B.S. in Computer Science at the
                          Universidade Federal do Pará, Brazil.
                        </p>
                        <p>
                          Outside of research I am an avid consumer and
                          collector of all things fantasy and sci-fi.
                        </p>
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <Card className={classes.paper}>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>
                    PROSPECTIVE PHD STUDENTS
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12}>
                      <div className={classes.textStyle}>
                        {/* <p>
                          I am a fifth year PhD candidate working with{" "}
                          <a href="http://www.depstein.net/"> Daniel Epstein</a>{" "}
                          and
                          <a href="https://www.gillianhayes.com/">
                            {" "}
                            Gillian Hayes
                          </a>{" "}
                          in the Informatics department at the University of
                          California, Irvine.
                        </p> */}
                        <p>
                          I am recruiting PhD Students! (Deadlines are Jan 1st
                          each year.)
                        </p>
                        <p>
                          If you are interested in Human-Computer Interaction
                          (HCI) for health and wellbeing, feel free to contact
                          me. Attach your CV and add the keyword 'Tatooine' in
                          your subject line or email text so I know you read my
                          website. If I don't respond right away, don't be
                          discouraged.
                        </p>

                        <p>
                          When contacting me, explain why you want to do a PhD
                          focused on HCI. If your sole focus is on Machine
                          Learning and AI, there are other advisors better
                          suited for you. Based on the emails I have been
                          receiving, I can tell when genAI/chatGPT was used. I
                          tend to ignore such emails.
                        </p>

                        <p>
                          Feel free to apply to either the{" "}
                          <a href="https://cs.uiowa.edu/graduate/phd-computer-science">
                            {" "}
                            CS PhD Program
                          </a>{" "}
                          or{" "}
                          <a href="https://cs.uiowa.edu/graduate/phd-informatics">
                            {" "}
                            Informatics PhD program
                          </a>{" "}
                          at the University of Iowa.
                        </p>
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default withStyles(styles)(Main)

export const query = graphql`
  query {
    myImage: file(relativePath: { eq: "me.png" }) {
      childImageSharp {
        fixed(width: 191, height: 275, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`
